<template>
  <v-card>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <div class="d-flex justify-space-between mx-1">
      <v-card-title>
        {{ $t('notificationsMenu') }}
      </v-card-title>
      <v-card-actions>
        <v-menu>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :disabled="allNotificationsRead" @click="markAllAsRead">
              <v-list-item-icon>
                <v-icon :disabled="allNotificationsRead">mdi-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="pr-5">{{ $t('markAllNotifAsRead') }}</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="notifications.length === 0" @click="deleteNotifications">
              <v-list-item-icon>
                <v-icon :disabled="notifications.length === 0">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="pr-5">{{ $t('deleteAll') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </div>
    <v-card-subtitle class="py-0" :class="$vuetify.breakpoint.xs ? ['px-5'] : ['mx-1']">
      <v-autocomplete
        ref="patients"
        v-model="selectedPatient"
        :items="patients"
        item-text="name"
        return-object
        clearable
        outlined
        dense
        class="pt-0 mt-0"
        :no-data-text="$t('noDataAvailable')"
        :label="$t('patient')"
        @input="handlePatientChange"
      />
    </v-card-subtitle>
    <v-divider></v-divider>
    <!-- scrollable content -->
    <v-card-text>
      <v-expansion-panels accordion multiple>
        <!-- circular loader -->
        <v-expansion-panel v-if="loading">
          <v-expansion-panel-header hide-actions>
            <WaitModal :show="loading" />
          </v-expansion-panel-header>
        </v-expansion-panel>
        <!-- notifications panels -->
        <v-expansion-panel v-for="item in notifications" :key="item.id" @click.prevent="onClick()">
          <v-expansion-panel-header :class="{ 'pa-2': $vuetify.breakpoint.xs }">
            <v-row :class="{ 'font-weight-black': !item.read }" dense class="mr-2 text-body-2" align="center">
              <v-col cols="auto" :class="{ 'mr-2': !$vuetify.breakpoint.xs }">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click.stop="onClick()">
                      <v-icon :color="item.read ? 'grey' : 'primary'" @click="toggleUpdateIsRead(item)"
                        >mdi-circle-medium</v-icon
                      >
                    </v-btn>
                  </template>
                  {{ item.read ? $t('markUnread') : $t('markRead') }}
                </v-tooltip>
              </v-col>
              <v-col>
                <v-row dense>
                  <v-col>
                    <span class="text--secondary">{{ item.data.patientName }}</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="text--secondary">{{ getFormattedTime(item.createdAt) }}</span>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    {{ getNotificationTitle(item) }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense class="text-body-2 mr-8" :class="$vuetify.breakpoint.xs ? ['ml-6'] : ['ml-12']">
              <v-col>
                <span :class="{ 'font-weight-black': !item.read }">{{
                  getNotificationDescription(item).description
                }}</span>
              </v-col>
            </v-row>
            <v-row dense class="mr-8" :class="$vuetify.breakpoint.xs ? ['ml-6'] : ['ml-12']">
              <v-col>
                <span class="text-body-2 text--secondary wrap-newline">{{
                  getNotificationDescription(item).value
                }}</span>
              </v-col>
            </v-row>
            <v-row dense class="mr-8" :class="$vuetify.breakpoint.xs ? ['ml-6'] : ['ml-12']">
              <v-col>
                <span class="text-body-2 text--secondary wrap-newline">{{
                  getNotificationDescription(item).configuration
                }}</span>
              </v-col>
            </v-row>
            <v-row v-if="isFDEorINC(item)" dense class="mr-8" :class="$vuetify.breakpoint.xs ? ['ml-6'] : ['ml-12']">
              <v-col>
                <span class="text-body-2 text--secondary wrap-newline"
                  >{{ $t('patientRoomAlert') }}: {{ getNotificationDescription(item).patientRoom }}</span
                >
              </v-col>
            </v-row>

            <v-row justify="end">
              <v-col cols="auto">
                <v-btn @click="toggleUpdateIsRead(item)">
                  {{ item.read ? $t('markUnread') : $t('markRead') }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="deleteNotificationById(item.id)">
                  {{ $t('delete') }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="goToNotificationSource(item)">
                  {{ $t('openNotification') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- no data message -->
        <v-expansion-panel v-if="!loading && notifications.length === 0">
          <v-expansion-panel-header hide-actions>
            <v-row class="justify-center my-5">
              {{ $t('noDataAvailable') }}
            </v-row>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-divider />

    <Pagination
      ref="pagination"
      :count="notificationCount"
      @page-update="handlePageUpdate"
      @items-per-page-update="handleItemsPerPageUpdate"
    />
  </v-card>
</template>

<script>
import accessibility from '@/accessibilityMixin';
import callbackRequestEventBus from '@/components/CallbackRequest/callbackRequestEventBus';
import notificationService from '@/services/notificationService';
import patientService from '@/services/patientService';
import translation, { LanguageVue } from '@/translationMixin';
import { isDateToday } from '@/utils/dateUtils';
import { format } from 'date-fns';
import { ActivityTypes } from '../PatientMonitoring/constants';
import { NotificationTypesVC } from './constants';
import Pagination from '@/components/Pagination.vue';

let globalSelectedPatient = null;
let globalOffset = 0;

export default {
  name: 'NotificationModal',

  components: { Pagination },

  mixins: [translation, accessibility],

  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      notifications: [],
      limit: 5,
      offset: 0,
      notificationCount: 0,
      patients: [],
      selectedPatient: null,
      panel: [],
      expanded: [],
      userLanguage: LanguageVue.getLanguage(),
    };
  },

  computed: {
    allNotificationsRead() {
      return this.notifications.every((notification) => notification.read);
    },
  },

  watch: {
    selectedPatient() {
      globalSelectedPatient = this.selectedPatient;
    },
  },
  async created() {
    this.NotificationTypesVC = NotificationTypesVC;
    this.loadSelectedPatientAndOffset();
    this.fetchPatients();
    this.init();
  },

  methods: {
    init: async function () {
      this.expanded = [];
      await this.fetchNotifications();
    },

    loadSelectedPatientAndOffset() {
      this.selectedPatient = globalSelectedPatient;
      this.offset = globalOffset;
    },

    async fetchPatients() {
      try {
        this.loading = true;
        const fetchedPatients = await patientService.getPatientsWithNotifications();

        fetchedPatients.map((patient) =>
          this.patients.push({ id: patient.id, name: `${patient.lastName}, ${patient.firstName}` })
        );
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async fetchNotifications() {
      try {
        this.loading = true;
        let patientId = null;

        if (this.selectedPatient) {
          patientId = this.selectedPatient.id;
        }

        const { count, results: notifications } = await notificationService.getNotifications(
          this.limit,
          this.offset,
          patientId
        );

        this.notificationCount = count;
        this.notifications = notifications;
        this.$nextTick(() => {
          this.updatePagination();
        });
        this.$emit('ui-updated');
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    updatePagination() {
      const PaginationComponent = this.$refs.pagination;
      if (PaginationComponent) {
        PaginationComponent.setStartAndEndIndexes();
      }
    },

    handlePatientChange(patient) {
      this.selectedPatient = patient;
      this.handlePageUpdate(1);
      this.resetPagination();
    },

    handleItemsPerPageUpdate(itemsPerPage) {
      this.limit = itemsPerPage === 'all' ? this.notificationCount : itemsPerPage;
      this.fetchNotifications();
    },

    handlePageUpdate(page) {
      this.offset = (page - 1) * this.limit;
      globalOffset = this.offset;
      if (this.notifications.length > 0) {
        this.fetchNotifications();
      }
    },

    resetPagination() {
      const PaginationComponent = this.$refs.pagination;
      if (PaginationComponent) {
        PaginationComponent.changePageTo(1);
      } else {
        console.error('Pagination reference is undefined.');
      }
    },

    async markAllAsRead() {
      const patientId = this.selectedPatient ? this.selectedPatient.id : null;
      const originalNotifications = [...this.notifications];

      try {
        this.notifications = this.notifications.map((notification) =>
          !patientId || parseInt(notification.data.patientId) === parseInt(patientId)
            ? { ...notification, read: true }
            : notification
        );

        if (patientId) {
          await notificationService.markAllNotificationsOfPatientAsRead(patientId);
        } else {
          await notificationService.markAllNotificationsAsRead();
        }
      } catch (error) {
        // revert back to original state if request fails
        this.notifications = originalNotifications;
        this.error = error;
      }
    },

    async deleteNotifications() {
      const patientId = this.selectedPatient ? this.selectedPatient.id : null;
      const originalNotifications = [...this.notifications];

      try {
        this.loading = true;

        if (patientId) {
          await notificationService.deleteAllNotificationsOfPatient(patientId);
          this.patients = this.patients.filter((patient) => patient.id !== patientId);
          this.selectedPatient = null;
        } else {
          await notificationService.deleteAllNotifications();
          this.notificationCount = 0;
          this.notifications = [];
          this.patients = [];
        }

        this.$emit('notifs-deleted');

        this.resetPagination();
      } catch (error) {
        // revert back to original state if request fails
        this.notifications = originalNotifications;
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async deleteNotificationById(notificationId) {
      try {
        this.loading = true;

        await notificationService.deleteNotificationById(notificationId);

        this.$emit('notifs-deleted');
        this.resetPagination();
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async toggleUpdateIsRead(notification) {
      notification.read = !notification.read;

      const data = {
        read: notification.read,
      };

      try {
        await notificationService.updateNotificationReadStatus(notification.id, data);
      } catch (error) {
        this.error = error;
      }
    },

    getFormattedTime(createdAt) {
      const date = new Date(createdAt);

      const options = {
        hour: 'numeric',
        minute: 'numeric',
      };
      if (this.userLanguage === 'en') {
        options.hour12 = true;
      }

      if (isDateToday(createdAt)) {
        return date.toLocaleTimeString(this.userLanguage, options);
      } else {
        const dateString = format(date, 'yyyy-MM-dd');
        const timeString = date.toLocaleTimeString(this.userLanguage, options);
        return `${dateString} ${timeString}`;
      }
    },

    getNotificationType(notification) {
      const notificationType = notification.notificationType;

      switch (notificationType) {
        case NotificationTypesVC.ACT_ALERT:
          return notification.data?.isPriorityAlert
            ? this.$t('notificationPriorityAlert')
            : this.$t('notificationAlertType');
        case NotificationTypesVC.NEW_MESSAGE:
          return `${this.$t('notificationNewMessageType')} ${notification.data.senderName}`;
        case NotificationTypesVC.NEW_CALLBACK_REQUEST:
          return this.$t('notificationCallBackRequest');
      }
    },

    getAlertValue(data) {
      return data.alertValue[this.userLanguage];
    },

    getAlertConfiguration(data) {
      return data.alertConfiguration[this.userLanguage];
    },

    getPatientRoom(data) {
      return data.patientRoom;
    },

    onClick() {
      const curr = this.expanded;
      this.expanded = curr === undefined ? 0 : undefined;
      this.$emit('ui-updated');
    },

    async goToNotificationSource(notification) {
      if (notification.read === false) {
        await this.toggleUpdateIsRead(notification);
      }

      if (notification.notificationType === NotificationTypesVC.NEW_CALLBACK_REQUEST) {
        callbackRequestEventBus.$emit('openCallbackRequestsNavigation');
      } else {
        let notificationId = notification.id;
        this.$router.push({ name: 'NotificationRedirect', params: { notificationId } });
      }

      this.closeDialog();
    },

    getNotificationTitle: function (item) {
      const notificationType = item.notificationType;

      switch (true) {
        case notificationType === NotificationTypesVC.NEW_MESSAGE:
          return `${this.getNotificationType(item)}  -  ${item.data.conversationSubject}`;

        case notificationType === NotificationTypesVC.ACT_ALERT:
          return `${this.getNotificationType(item)}  -  ${item.data.activityName}`;

        case notificationType === NotificationTypesVC.NEW_CALLBACK_REQUEST:
          return this.getNotificationType(item);
      }
    },

    getNotificationDescription: function (item) {
      let description, value, configuration, patientRoom;
      const notificationType = item.notificationType;

      switch (true) {
        case notificationType === NotificationTypesVC.NEW_MESSAGE:
          description = `${this.$t('newMessage')}  -  ${item.data.conversationSubject}`;
          value = null;
          configuration = null;
          break;

        case notificationType === NotificationTypesVC.ACT_ALERT:
          description = `${this.$t(
            item.data.isPriorityAlert ? 'priorityAlertNotificationTriggered' : 'alertNotificationTriggered'
          )}  -  ${item.data.activityName}`;
          value = this.getAlertValue(item.data);
          configuration = this.getAlertConfiguration(item.data);
          patientRoom = this.getPatientRoom(item.data);
          break;

        case notificationType === NotificationTypesVC.NEW_CALLBACK_REQUEST:
          description = `${this.$t('newCallBackRequest')} ${item.data.patientName.replace(',', '')}`;
          value = null;
          configuration = null;
          break;
      }

      return {
        description: description,
        value: value,
        configuration: configuration,
        patientRoom: patientRoom,
      };
    },

    isFDEorINC(item) {
      return item?.data?.activityTypeCode === ActivityTypes.FDE || item?.data?.activityTypeCode === ActivityTypes.INC;
    },
  },
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
  max-width: 600px;
  max-height: 600px;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
  max-height: 400px;
  padding: 0 !important;
}

.v-card__title {
  word-break: normal;
}

.v-autocomplete {
  width: fit-content;
}
</style>
