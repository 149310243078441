import { BaseService } from './baseService';

class VersionService {
  async getVersion() {
    const service = new BaseService('', { useAccessToken: false });

    const versionData = await service.get(`static/version.json`);
    return versionData.productVersion;
  }
}

export default new VersionService();
