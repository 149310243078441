<template>
  <v-dialog v-if="visible" :value="true" max-width="400px" @input="close">
    <v-card>
      <v-card-title>
        <img ref="logoImg" class="logo mx-auto" src="/static/img/logo-gray.png" />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div>{{ `${$t('about.version')} ${version}` }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><a href="https://virtuosetechnologies.com" target="_blank"
              >{{ $t('about.virtuoseTech') }}<v-icon color="primary" class="ml-1" small>mdi-open-in-new</v-icon></a
            ></v-col
          >
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">{{ $t('close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import translationMixin from '@/translationMixin';
import versionService from '@/services/versionService';

export default {
  name: 'AboutModal',
  mixins: [translationMixin],
  model: {
    prop: 'visible',
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      version: '',
    };
  },
  watch: {
    async visible() {
      if (this.visible && !this.version) {
        this.version = await versionService.getVersion();
      }
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 100px;
}
</style>
