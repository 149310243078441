class PatientNoteFormData {
  constructor() {
    this.reset();
  }

  reset() {
    this.id = null;
    this.noteType = { text: '', value: '' };
    this.noteContent = '';
    this.noteDate = null;
    this.interventionDate = null;
    this.interventionStartTime = null;
    this.interventionEndTime = null;
    this.interventionTime = null;
    this.createdAt = null;
    this.createdBy = null;
  }

  setData(data) {
    this.id = data.id;
    this.noteType = data.noteType || null;
    this.noteContent = data.noteContent || null;
    this.noteDate = data.noteDate || null;
    this.interventionDate = data.interventionDate || null;
    this.interventionStartTime = data.interventionStartTime || null;
    this.interventionEndTime = data.interventionEndTime || null;
    this.interventionTime = data.interventionTime || null;
    this.createdAt = data.createdAt || null;
    this.createdBy = data.createdBy || null;
  }
}

export { PatientNoteFormData };
